.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 27px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(-90deg);
}

.header__top .hamburger {
  height: 48px;
  vertical-align: middle;
  padding: 10px;
}

.header__top .hamburger-inner, .header__top .hamburger-inner:before, .header__top .hamburger-inner:after, .header__top .hamburger.is-active .hamburger-inner, .header__top .hamburger--collapse.is-active .hamburger-inner:before, .header__top .hamburger--collapse.is-active .hamburger-inner:after {
  background: #795548;
}

@media (max-width: 450px) {
  .hamburger {
    float: right;
  }
}

/*# sourceMappingURL=hamburger.8da5f325.css.map */
